html {
  width: 100%;
  height: 100%;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  width: calc(100% - 5px * 2);
  height: calc(100% - 5px * 2);
  margin: 5px;
}

#result {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
